import axios from 'axios'

import pinsRoutes from './routes/pinsRoutes'

const axiosInstance = axios.create()
const pins = new pinsRoutes(axiosInstance)

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port)
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
}

const apiService = {
    configure,
    useAuth,
    pins
}

export default apiService