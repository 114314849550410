export default class {
  constructor(apiService) {
      this.apiService = apiService
  }

  getAccount(siteKey) {
    return this.apiService.get(`/${siteKey}/award/kiosk`)
  }

  getStaffAccount(siteKey) {
    return this.apiService.get(`/${siteKey}/award/kiosk/staff`)
  }
}