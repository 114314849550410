export default class {
  constructor(apiService) {
      this.apiService = apiService
  }

  getOne(sentenceNumber) {
    return this.apiService.get(`/account/sentence/${sentenceNumber}`)
  }

  getOneStaff(email) {
    return this.apiService.get(`/account/staff/${email}`)
  }

  getPreAuth(sentenceNumber){
    return this.apiService.get(`/preauth/sentence/${sentenceNumber}`)
  }

  getPreAuthStaff(email) {
    return this.apiService.get(`/preauth/email/${email}`)
  }
}