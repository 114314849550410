<template>
  <NavVue>
    <div id="pointsView">
      <v-container>
        <v-row class="ma-0 pa-0">
          <v-col class="text-center px-0" cols="12" sm="4" md="4" lg="3">
            <v-card width="100%" outlined tile>
              <h3 class="mb-0">
                <em>Current balance</em>
              </h3>
              <h1 class="display-sm-4 my-2 display-4 font-weight-bold">
                <em class="secondary--text">{{ points }}</em>
              </h1>
              <p class="mb-0">
                <em>&nbsp;points available to spend</em>
              </p>
            </v-card>
            <v-btn
              class="pointsBtns primary my-4"
              @click="selectRoute('/Shop')"
              x-large
            >
              Spend Points
              <v-icon class="ml-2">mdi-cart</v-icon>
            </v-btn>
            <v-btn
              class="pointsBtns primary"
              v-if="transactions.length >=10"
              @click="selectRoute('/Transactions')"
              x-large
            >
              More Transactions
              <v-icon class="ml-2">mdi-eye</v-icon>
            </v-btn>
            <v-row align="center" justify="center" class="my-1 px-0">
              <v-col cols="12" md="6" sm="12" lg="6" v-if="guide">
                <v-btn
                  class="pointsBtns primary"
                  @click="openGuide"
                  x-large
                  style="font-size: 16px"
                >
                  Quick Guide

                  <v-icon size="20" class="ml-2"
                    >mdi-file-document-outline</v-icon
                  >
                </v-btn>
              </v-col>
              <v-col cols="12" md="6" sm="12" lg="6" v-if="booklet">
                <v-btn
                  class="pointsBtns primary"
                  @click="openBooklet"
                  x-large
                  style="font-size: 16px"
                >
                  Booklet
                  <v-icon size="20" class="ml-2"
                    >mdi-file-document-outline</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col sm="8" md="8" lg="9">
            <v-card class="pa-2" outlined tile>
              <v-data-table
                :headers="headers"
                :items="transactions"
                :items-per-page="20"
                :footer-props="{
                  'items-per-page-options': [10, 20, 30, 40, 50],
                }"
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:[`item.timestamp`]="{ item }">
                  {{ transactionTimestamp(item.timestamp) }}
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    small
                    class="ma-2"
                    text-color="white"
                    :color="
                      item.status == 0
                        ? 'green'
                        : '' || item.status == 1
                        ? 'orange'
                        : '' || item.status == 2
                        ? 'red'
                        : '' || item.status == 3
                        ? 'cyan'
                        : ''
                    "
                  >
                    {{
                      item.status == 0
                        ? "Complete"
                        : "" || item.status == 1
                        ? "Pending"
                        : "" || item.status == 2
                        ? "Declined"
                        : "" || item.status == 3
                        ? "Actioned"
                        : ""
                    }}
                  </v-chip>
                </template>
                <template v-slot:[`item.transactionMonetaryValue`]="{ item }">
                  <div
                    v-if="
                      item.transactionAmount < 0 &&
                      item.transactionMonetaryValue > 0
                    "
                  >
                    £{{ item.transactionMonetaryValue.toFixed(2) }}
                  </div>
                  <div v-else>-</div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="pdfDialog" fullscreen>
          <v-card tile>
            <v-card-title class="py-2 px-2">
              <v-btn small color="black" icon dark @click="pdfDialog = false">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-card-title>
           <div id="pdfContainer"><vue-pdf-embed :source="pdfSource" disableAnnotationLayer disableTextLayer /></div>
          </v-card>
        </v-dialog>
        <!-- <v-overlay v-model="pdfDialog" :value="pdfSource" @click="src = null">
          <div id="pdfContainer"><vue-pdf-embed :source="pdfSource"  :page="page" disableAnnotationLayer disableTextLayer /></div>
        </v-overlay> -->
      </v-container>
    </div>
  </NavVue>
</template>
<script>
import NavVue from "./Nav.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

export default {
  // eslint-disable-next-line
  name: "Points",
  computed: {
    cols() {
      const { lg, sm } = this.$vuetify.breakpoint;
      return lg ? [3, 9] : sm ? [9, 3] : [6, 6];
    }
  },
  components: {
    NavVue,
    VuePdfEmbed
  },
  data() {
    return {
      pdfSource: "",
      pdfDialog: false,
      user: null,
      openId: null,
      transactions: [],
      suspensionEndDate: null,
      points: null,
      scroll: "",
      guide: null,
      booklet: null,
      colors: ["#243f56"],
      awards: [],
      sortBy: "timestamp",
      sortDesc: true,
      headers: [
        {
          text: "Time",
          align: "start",
          sortable: false,
          value: "timestamp"
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Reason",
          align: "start",
          sortable: false,
          value: "awardReason"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Point Amount",
          align: "end",
          sortable: false,
          value: "transactionAmount"
        },
        {
          text: "Point Balance",
          align: "end",
          sortable: false,
          value: "accountBalance"
        },
        {
          text: "Cost",
          align: "end",
          sortable: false,
          value: "transactionMonetaryValue"
        }
      ]
    };
  },
  methods: {
    initialize() {
      if(typeof this.$auth.userProfile.role !== "undefined"){
        if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super') {
          this.$prisoner = false
        }
        else {
          this.$prisoner = true
        }
        this.getAccount();
      }
      else{
        setTimeout(this.initialize, 250);
      }
    },
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
      this.scroll = "overflow-y-auto";
    },
    selectRoute(routeName) {
      this.$router.push(routeName);
    },
    openGuide() {
      this.pdfDialog = true;
      this.pdfSource = this.guide;
    },
    openBooklet() {
      this.pdfDialog = true;
      this.pdfSource = this.booklet;
    },
    async getTransactions() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.transaction.getOne(this.$siteKey, this.user.accountID);
        }
        else {
          response = await this.$crisApi.transaction.getOneStaff(this.$siteKey, this.user.staffAccountID)
        }
        
        this.tickerTapes = response.data;
        this.transactions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAccount() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.account.getPreAuth(this.$auth.userProfile.sentence);
        }
        else {
          response = await this.$crisApi.account.getPreAuthStaff(this.$auth.userProfile.name)
        }
        this.$siteKey = this.$prisoner ? this.$auth.userProfile.site : response.data.siteKey;
        this.user = response.data;
        this.getTransactions();
        this.getFiles();
        this.points = this.user.balance.toLocaleString();
        this.getAward();
      } catch (error) {
        console.error(error);
      }
    },
    async getFiles() {
      try {
        let response = await this.$crisApi.site.getFiles(this.$siteKey, this.user.location ? this.user.location.zoneID : -1);
        if (this.$prisoner) {
          if (response.data.guide) {
            this.guide = "data:application/pdf;base64," + response.data.guide;
          }
          if (response.data.booklet) {
            this.booklet = "data:application/pdf;base64," + response.data.booklet;
          }
        }
        else {
          if (response.data.staffGuide) {
            this.guide = "data:application/pdf;base64," + response.data.staffGuide;
          }
          if (response.data.staffBooklet) {
            this.booklet = "data:application/pdf;base64," + response.data.staffBooklet;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAward() {
      let response
      if (this.$prisoner) {
        response = await this.$crisApi.award.getAccount(this.$siteKey);
      }
      else {
        response = await this.$crisApi.award.getStaffAccount(this.$siteKey)
      }
      this.awards = response.data;
    },
    transactionTimestamp(item) {
      return new Date(item).toLocaleString();
    }
  },
  created() {
    this.initialize()
  }
};
</script>
