<template>
  <div>
    <div>
      <NavVue>
        <v-dialog
          overlay-opacity="1"
          persistent
          v-model="suspensionBoolean"
          width="40%"
        >
          <v-card>
            <v-card-text>
              <v-row no-gutters justify="center" align="center">
                <v-col cols="12" class="text-center black--text py-4">
                  <b class="title text-h5 secondary--text">
                    Your Account is Suspended
                  </b>
                </v-col>
                <v-col class="pb-2 text-center">
                  <div class="subtitle-2 black--text">Suspension End Date:</div>
                  <div class="black--text">{{ suspensionDateFormatted }}</div>
                </v-col>
                <v-col cols="12" md="12" class="py-2 text-center">
                  <div class="subtitle-2 black--text">Suspension Reason:</div>
                  <div class="black--text">{{ suspensionReason }}</div>
                </v-col>
                <v-col cols="12" md="12" class="py-2 text-center">
                  <div class="black--text">Please note, whilst you are suspended you won't be able to purchase Incentives or receive Awards</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!staffAccount"
                @click="suspensionBoolean=false"
              >
              Continue
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row class="homeContent" justify="center" align="center" style="background-image: url('../happy-rewards.jpg');">
          <v-col cols="12">
            <v-row justify="center" align="center">
              <v-card
                class="mx-auto primary"
                width="70%"
                raised
                oulined
                style="margin-top: 30px;"
              >
                <v-card-title class="white--text justify-center align-center">
                  Noticeboard
                </v-card-title>
                <v-card-text>
                  <v-textarea
                  dark
                    v-if="noticeboard"
                    readonly
                    :value="noticeboard"
                  >
                  </v-textarea>
                </v-card-text>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center" class="">
              <v-card
                class="mx-auto homeCard primary"
                width="450"
                raised
                outlined
              >
                <v-list-item three-line>
                  <v-list-item-content class="white--text text-center">
                    <div class="mb-2 sizeFive">Welcome,</div>
                    <v-list-item-title
                      class="sizeThree"
                      style="font-size: 24px; margin-bottom: 0px"
                      >{{ $auth.userProfile.name }}</v-list-item-title
                    >
                    <v-divider
                      class="mx-1 mb-4"
                      style="margin: 8px"
                    ></v-divider>
                    <div style="text-align: center" class="mb-2 sizeFive">
                      Account Details
                    </div>
                    <v-list-item-subtitle
                      class="white--text"
                      style="text-align: center"
                      ><b> Name:</b> {{ $auth.userProfile.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </NavVue>
    </div>
  </div>
</template>
<script>
import NavVue from "./Nav.vue";
export default {
  // eslint-disable-next-line
  name: "Home",
  data() {
    return {
      awards: [
        { description: "Award 1" },
        { description: "Award 2" },
        { description: "Award 3" }
      ],
      balance: null,
      user: null,
      forenames: null,
      surname: null,
      Initials: null,
      suspensionBoolean: false,
      suspensionDateFormatted: "",
      suspensionReason: "",
      userInfo: "",
      accountActive: true,
      staffAccount: false,
      noticeboard: null,
    };
  },
  computed: {
    randomAwards() {
      if (this.awards.length === 0) {
        return [];
      }
      const selectedAwards = [];
      const awards = [];
      var max = 3
      if (this.awards.length < 3) { max = this.awards.length}
      for (let i = 0; i < max; i++) {
        let award;
        do {
          award = this.awards[Math.floor(Math.random() * this.awards.length)];
        } while (selectedAwards.includes(award));
        selectedAwards.push(award);
        awards.push(award);
      }
      return awards;
    },
    awardDescriptions() {
      return this.randomAwards
        .map(award => `${award.description} = ${award.value} points`)
        .join("<br>");
    },
    awardBalance() {
      return this.randomAwards.reduce(
        (acc, award) => acc + award.value,
        this.balance
      );
    }
  },
  components: {
    NavVue
  },
  methods: {
    async getAllNoticeboard(){
      //let response = await this.$crisApi.site.getOne(this.$siteKey);
      var response = await this.$crisApi.site.getAllNoticeboard(this.$siteKey);
      if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super'){
        this.noticeboard = response.data.staffNoticeboard;
      }
      else{
        this.noticeboard = response.data.prisonerNoticeboard;
      }
    },
    initialize() {
      if(typeof this.$auth.userProfile.role !== "undefined"){
          if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super') {
            this.$prisoner = false
          }
          else {
            this.$prisoner = true
          }
          this.getAccount();
        }
        else{
            setTimeout(this.initialize, 250);
        }
    },
    selectRoute(routeName) {
      this.$router.push(routeName);
    },
    async getAward() {
      let response
      if (this.$prisoner) {
        response = await this.$crisApi.award.getAccount(this.$siteKey);
      }
      else {
        response = await this.$crisApi.award.getStaffAccount(this.$siteKey)
      }
      this.awards = response.data;
    },
    async getAccount() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.account.getPreAuth(this.$auth.userProfile.sentence);
        }
        else {
          response = await this.$crisApi.account.getPreAuthStaff(this.$auth.userProfile.name)
        }
        console.log(response)
        this.$siteKey = this.$prisoner ? this.$auth.userProfile.site : response.data.siteKey;
        this.staffAccount = Object.keys(response.data).includes("staffAccountID")
        this.suspensionBoolean = !response.data.active
        this.userInfo = response.data
        this.balance = response.data.balance;
        this.getAward();
        this.getAllNoticeboard();
      } catch (error) {
        console.error(error);
      }
    },
    suspended(date) {
      var today = new Date();
      today = today.getTime();

      if (date != null) {
        var sDate = new Date(date);
        var tDate = sDate.getTime();

        if (tDate > today) {
          var dDay = String(sDate.getDate()).padStart(2, "0");
          var dMonth = String(sDate.getMonth() + 1).padStart(2, "0");
          var dYear = sDate.getFullYear();

          this.suspensionBoolean = true;
          this.suspensionReason = this.userInfo.suspensionReason;
          this.suspensionDateFormatted = dDay + "/" + dMonth + "/" + dYear;
        } else {
          this.suspensionBoolean = false;
        }
      }
    }
  },
  watch: {
    userInfo() {
      console.log(this.userInfo)
      if (this.$prisoner){
        if (this.userInfo.suspensionEndDate){
  
          this.suspended(this.userInfo.suspensionEndDate);
        }
        else{
          if (this.userInfo.pointsActive == 0){
            this.suspensionBoolean = true;
            this.suspensionReason = this.userInfo.suspensionReason ? this.userInfo.suspensionReason : 'No reason given';
            this.suspensionDateFormatted = "Undetermined"
          }
        }
      }
      else{
        if (this.userInfo.status == 0){
          this.suspensionBoolean = true;
          this.suspensionReason = 'No reason given';
          this.suspensionDateFormatted = "Undetermined"
        }

      }
    }
  },
  created() {
    this.initialize()
    if (this.$route.path.includes("home")) {
      document.getElementById("Points").classList.remove("highlighted");
      //getAllNoticeboard
    }
  }
};
</script>
