<template>
  <NavVue>
    <v-row class="px-3 pt-6">
      <v-col class="pb-0" cols="12">
        <a @click="selectRoute('/Points')" class="rtrnHeader">
          <h3 class="sizeTwo">
            <v-icon class="pb-1" size="30">mdi-arrow-left</v-icon> Return to
            points summary
          </h3></a
        >
      </v-col>
      <v-col class="pt-0" cols="12" md="12" sm="12">
        <v-card flat class="pt-0">
          <v-card-title class="px-1 pt-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text class="px-1">
            <v-data-table
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :headers="headers"
              :items="transactions"
              hide-default-footer
              disable-pagination
              class="elevation-0"
              :search="search"
            >
              <template v-slot:[`item.timestamp`]="{ item }">
                {{ transactionTimestamp(item.timestamp) }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  class="ma-2"
                  text-color="white"
                  :color="
                      item.status == 0
                        ? 'green'
                        : '' || item.status == 1
                        ? 'orange'
                        : '' || item.status == 2
                        ? 'red'
                        : '' || item.status == 3
                        ? 'cyan'
                        : ''
                    "
                >
                  {{
                      item.status == 0
                        ? "Complete"
                        : "" || item.status == 1
                        ? "Pending"
                        : "" || item.status == 2
                        ? "Declined"
                        : "" || item.status == 3
                        ? "Actioned"
                        : ""
                    }}
                </v-chip>
              </template>
              <template v-slot:[`item.transactionMonetaryValue`]="{ item }">
                <div
                  v-if="
                    item.transactionAmount < 0 &&
                    item.transactionMonetaryValue > 0
                  "
                >
                  £{{ item.transactionMonetaryValue.toFixed(2) }}
                </div>
                <div v-else>-</div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </NavVue>
</template>
<script>
import NavVue from "./Nav.vue";
export default {
  // eslint-disable-next-line
  name: "Transactions",
  data() {
    return {
      user: null,
      openId: null,
      transactions: [],
      suspensionEndDate: null,
      points: 75,
      sortBy: "timestamp",
      sortDesc: true,
      search: "",
      headers: [
        {
          text: "Time",
          align: "start",
          sortable: false,
          value: "timestamp"
        },
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Reason",
          align: "start",
          sortable: false,
          value: "awardReason"
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Point Amount",
          align: "right",
          sortable: false,
          value: "transactionAmount"
        },
        {
          text: "Point Balance",
          align: "right",
          sortable: false,
          value: "accountBalance"
        },
        {
          text: "Cost",
          align: "right",
          sortable: false,
          value: "transactionMonetaryValue"
        }
      ]
    };
  },
  components: {
    NavVue
  },
  methods: {
    transactionTimestamp(item) {
      return new Date(item).toLocaleString();
    },
    selectRoute(routeName) {
      this.$router.push(routeName);
    },
    async getTransactions() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.transaction.getOne(this.$siteKey, this.user.accountID);
        }
        else {
          response = await this.$crisApi.transaction.getOneStaff(this.$siteKey, this.user.staffAccountID)
        }
        this.transactions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAccount() {
      try {
        let response
        if (this.$prisoner) {
          response = await this.$crisApi.account.getPreAuth(this.$auth.userProfile.sentence);
        }
        else {
          response = await this.$crisApi.account.getPreAuthStaff(this.$auth.userProfile.name)
        }
        this.$siteKey = this.$prisoner ? this.$auth.userProfile.site : response.data.siteKey;
        this.user = response.data;
        this.getTransactions();
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    if (this.$auth.userProfile.role == 'staff' || this.$auth.userProfile.role == 'super') {
      this.$prisoner = false
    }
    else {
      this.$prisoner = true
    }
    this.getAccount();

    if (
      this.$route.path.includes("Shop") ||
      this.$route.path.includes("Transactions")
    ) {
      document.getElementById("Points").classList.add("highlighted");
    }
  }
};
</script>
