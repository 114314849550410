export default class {
    constructor(apiService) {
        this.apiService = apiService
    }

    getOne(siteKey) {
        return this.apiService.get(`/${siteKey}/site`)
    }

    getAllNoticeboard(siteKey){
        return this.apiService.get(`/${siteKey}/site/getAllNoticeboard`)
    }

    getFiles(siteKey, zone) {
        return this.apiService.get(`/${siteKey}/site/files/${zone}`)
    }
}