export default class {
  constructor(apiService) {
    this.apiService = apiService
  }

  getOne(siteKey, accountId) {
    return this.apiService.get(`/${siteKey}/transaction/${accountId}`)
  }

  getOneStaff(siteKey, accountId) {
    return this.apiService.get(`/${siteKey}/transaction/staff/${accountId}`)
  }

  purchase(siteKey, purchaseInformation) {
    return this.apiService.post(`/${siteKey}/transaction/purchase`, purchaseInformation)
  }
}