var render = function render(){var _vm=this,_c=_vm._self._c;return _c('NavVue',[_c('v-row',{staticClass:"px-3 pt-6"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('a',{staticClass:"rtrnHeader",on:{"click":function($event){return _vm.selectRoute('/Points')}}},[_c('h3',{staticClass:"sizeTwo"},[_c('v-icon',{staticClass:"pb-1",attrs:{"size":"30"}},[_vm._v("mdi-arrow-left")]),_vm._v(" Return to points summary ")],1)])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',{staticClass:"pt-0",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"px-1 pt-0"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',{staticClass:"px-1"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.headers,"items":_vm.transactions,"hide-default-footer":"","disable-pagination":"","search":_vm.search},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:`item.timestamp`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.transactionTimestamp(item.timestamp))+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","text-color":"white","color":item.status == 0
                      ? 'green'
                      : '' || item.status == 1
                      ? 'orange'
                      : '' || item.status == 2
                      ? 'red'
                      : '' || item.status == 3
                      ? 'cyan'
                      : ''}},[_vm._v(" "+_vm._s(item.status == 0 ? "Complete" : "" || item.status == 1 ? "Pending" : "" || item.status == 2 ? "Declined" : "" || item.status == 3 ? "Actioned" : "")+" ")])]}},{key:`item.transactionMonetaryValue`,fn:function({ item }){return [(
                  item.transactionAmount < 0 &&
                  item.transactionMonetaryValue > 0
                )?_c('div',[_vm._v(" £"+_vm._s(item.transactionMonetaryValue.toFixed(2))+" ")]):_c('div',[_vm._v("-")])]}}],null,true)})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }