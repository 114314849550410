import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home'
import Points from '@/views/Points'
import Shop from '@/views/Shop'
import Denied from '@/views/Denied'
import Transactions from '@/views/Transactions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '', redirect: '/Home' },
        { name: 'Home', path: '/Home', component: Home, meta: { requiresAuth: true} },
        { name: 'Points', path: '/Points', component: Points, meta: { requiresAuth: true }},
        { name: 'Shop', path: '/Shop', component: Shop, meta: { requiresAuth: true }},
        { name: 'Denied', path: '/Denied', component: Denied, meta: { requiresAuth: true }},
        { name: 'Transactions', path: '/Transactions', component: Transactions, meta: { requiresAuth: true }}
    ]
})

export default router