export default class {
    constructor(pdfService) {
        this.pdfService = pdfService
    }

    getPDFTypes(sitekey, pdfFile){
        return this.pdfService.get(`/v1.0/${sitekey}/cris/PDFBuilder/template/${pdfFile}/getPDFTypes`)
    }

    createFinishedPDF(sitekey, pdfFile, data){
        return this.pdfService.post(`/v1.0/${sitekey}/cris/PDFBuilder/template/${pdfFile}/create`, data)
    }
}