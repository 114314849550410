<template>
    <v-app>
        <v-snackbar :value="true" color="black" timeout="-1" centered>
            Only users with a role of prisoner can view this
        </v-snackbar>
    </v-app>
</template>
<script>
    export default {
        // eslint-disable-next-line
        name: 'Denied',
    }
</script>
