import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#4f5d66',
        secondary: '#ee2a23',
        accent: '#ee2a23'
      }
    }
  }
});