<template>
  <v-app>
    <router-view></router-view>
    <ErrorDialog class="logOutModal" ref="ErrorDialog"></ErrorDialog>
  </v-app>
</template>

<script>

import ErrorDialog from "./components/ErrorDialog.vue"

export default {
  name: 'App',
  components: {
    ErrorDialog
  },
  methods: {
    onError(error) {
      const dialogTitle = "An Error Occured"
      const dialogMessage = error
      this.$refs.ErrorDialog.open(dialogTitle,dialogMessage, this.inIframe()).then((confirm) => {
        if(confirm) {
          confirm()
        }
      })
    }
  },
  errorCaptured(error) {
    this.onError(error)
  }
};
</script>