export default class {
  constructor(apiService) {
    this.apiService = apiService
  }
  getOne(siteKey, accountId) {
      return this.apiService.get(`/${siteKey}/incentives/${accountId}`)
    }
  getAccountIncentives(siteKey, accountId) {
    return this.apiService.get(`/${siteKey}/incentive/account/${accountId}`)
  }

  getStaffAccountIncentives(siteKey, accountId) {
    return this.apiService.get(`/${siteKey}/incentive/staff/${accountId}`)
  }
}
