import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { createAuth } from '@/plugins/oidc'
import router from '@/router'
import dataApi from '@/services/dataApi'
import messagingApi from '@/services/messagingApi'
import pdfApi from '@/services/pdfApi'

(async () => {
  let response = await fetch(process.env.BASE_URL + 'config.json')
  const config = await response.json()
  const auth = await createAuth(config.oidc)

  auth.useRouter(router)
  Vue.prototype.$auth = auth

  dataApi.configure(config.dataApiServer)
  dataApi.useAuth(auth)
  Vue.prototype.$crisApi = dataApi

  messagingApi.configure(config.messagingApiServer)
  messagingApi.useAuth(auth)
  Vue.prototype.$messagingApi = messagingApi

  pdfApi.configure(config.pdfApiServer)
  pdfApi.useAuth(auth)
  Vue.prototype.$pdfApi = pdfApi

  Vue.prototype.$prisoner = true
  Vue.prototype.$siteKey = ''
  Vue.prototype.$messagingIncentiveIds = config.messagingApiServer.IncentiveIDs
  Vue.prototype.$messagesPerPoint = config.messagingApiServer.MessagesPerPoint

  new Vue({
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')
})()
