import axios from 'axios'

import accountRoutes from './routes/accountRoutes'
import awardRoutes from './routes/awardRoutes'
import incentiveRoutes from './routes/incentiveRoutes'
import transactionRoutes from './routes/transactionRoutes'
import siteRoutes from './routes/siteRoutes'

const axiosInstance = axios.create()
const account = new accountRoutes(axiosInstance)
const award = new awardRoutes(axiosInstance)
const incentive = new incentiveRoutes(axiosInstance)
const transaction = new transactionRoutes(axiosInstance)
const site = new siteRoutes(axiosInstance)

function configure(apiServer) {
    axiosInstance.defaults.baseURL = (apiServer.ssl ? 'https' : 'http') + '://' + apiServer.host + ((apiServer.ssl && apiServer.port == '443') || (!apiServer.ssl && apiServer.port == '80') || !apiServer.port ? '' : ':' + apiServer.port)
}

function useAuth(auth) {
    axiosInstance.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
        return config
    })
}

const apiService = {
    configure,
    useAuth,
    account,
    award,
    incentive,
    transaction,
    site
}

export default apiService